import _ from "lodash";
import Button from "components/Button";
import { Container, Wrapper } from "styles/pages/home/register-banner";

const Checkout = ({ toggleSignUpDialog, data = {}, toggleLoginDialog }) => {
  const { components } = data;

  const {
    BackgroundColor,
    Description: description,
    Title: title,
    SubTitle,
    Buttons,
    Image,
  } = components || {};

  return (
    <Container style={{ backgroundColor: BackgroundColor }} id="whats-new">
      <Wrapper>
        <div className="textSection">
          <div>
            <h2>{title}</h2>
            <h4>{SubTitle?.replace("Shirtly", "Vizuail")}</h4>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          {Buttons?.map((b, i) =>
            b?.Show ? (
              <Button
                key={`button${i}`}
                title={b?.ButtonName}
                containerStyle={{
                  marginTop: 40,
                  background: "transparent",
                  boxShadow: "none",
                  border: "1px solid #fff",
                  color: "#fff",
                  textTransform: "capitalize",
                  height: 48,
                  borderRadius: 6,
                  padding: "0 32px",
                }}
                onClick={() => {
                  if (b?.Modal === "SignIn") {
                    _.isFunction(toggleLoginDialog) && toggleLoginDialog();
                  } else if (b?.Modal === "SignUp") {
                    _.isFunction(toggleSignUpDialog) && toggleSignUpDialog();
                  }
                }}
              />
            ) : null
          )}
        </div>
        <div className="mask">
          <img src={Image} alt="Mask" />
        </div>
      </Wrapper>
    </Container>
  );
};

export default Checkout;
