import _ from "lodash";
import Button from "components/Button";
import DashboardWebPage from "pages/web-pages/Dashboard";
import Notifications from "pages/dashboard/Notifications";
import { Container, Wrapper, ButtonWrapper } from "styles/pages/home/banner";
import {
  DashboardWebPageWrapper,
  NotificationsWrapper,
} from "styles/pages/home";

const Banner = ({
  toggleSignUpDialog,
  toggleLoginDialog,
  data = {},
  dashboardData,
}) => {
  const { components } = data;

  const { BackgroundImage, Image, Title, Description, Buttons } =
    components || {};

  if (!BackgroundImage || !Image) {
    return null;
  }

  return (
    <Container image={BackgroundImage}>
      <Wrapper>
        <div className="textSection">
          <div className="text">
            <h1>{Title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: Description,
              }}
            />
          </div>

          <ButtonWrapper>
            {Buttons?.map((b, i) =>
              b?.Show ? (
                <Button
                  key={`button${i}`}
                  title={b?.ButtonName}
                  onClick={() => {
                    if (b?.Modal === "SignIn") {
                      _.isFunction(toggleLoginDialog) && toggleLoginDialog();
                    } else if (b?.Modal === "SignUp") {
                      _.isFunction(toggleSignUpDialog) && toggleSignUpDialog();
                    }
                  }}
                />
              ) : null
            )}
          </ButtonWrapper>
        </div>

        {/* <div className="imgSection">
          <img src={Image} alt="HeroBannerImage" />
        </div> */}
      </Wrapper>

      {/* {dashboardData && dashboardData?.length > 0 && (
        <DashboardWebPageWrapper>
          <DashboardWebPage data={dashboardData} isAdmin={false} />
          <NotificationsWrapper>
            <Notifications />
          </NotificationsWrapper>
        </DashboardWebPageWrapper>
      )} */}
    </Container>
  );
};

export default Banner;
