import {
  Container,
  Wrapper,
  CardWrapper,
  ImageSection,
} from "styles/pages/home/works";

const Card = ({ name, description, image }) => {
  return (
    <CardWrapper>
      <div className="card-image">
        <img src={image} alt="{workImages}" />
      </div>
      <h4>{name}</h4>
      <p>{description}</p>
    </CardWrapper>
  );
};

const Works = ({ data = {} }) => {
  const { components } = data || {};
  const { BackgroundColor, Title: title, SubTitle, Cards } = components || {};

  return (
    <Container style={{ backgroundColor: BackgroundColor }} id="how-it-works">
      <Wrapper>
        <div className="textSection">
          <h2 className="heading">{title}</h2>
          <p>{SubTitle}</p>
        </div>

        <ImageSection>
          {Cards?.map((item, index) => (
            <Card
              key={`works${index}`}
              name={item.CardTitle?.replace("Shirtly", "Vizuail")}
              description={item.CardSubTitle?.replace("Shirtly", "Vizuail")}
              image={item.CardImage}
            />
          ))}
        </ImageSection>
      </Wrapper>
    </Container>
  );
};

export default Works;
